import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignUpModel } from '../../shared/models/auth/signup.model';
import { AuthService } from '../auth-shell.service';

declare let $;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [AuthService]
})

export class ForgotPasswordComponent implements OnInit {
  public _signup: any;
  public forgotPasswordForm: FormGroup;
  public isMailSent: boolean = false;
  isCaptcha: boolean = true;
  Customer: any = [];
  Tenant: any = [];
  constructor(private router: Router, private fb: FormBuilder, private authService: AuthService) {
    this.forgotPasswordForm = fb.group({
      email: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.forgotPasswordPageLayout();

    this.forgotPasswordForm.get('email').valueChanges.subscribe(
      (e) => {
        if (e != "") {
          this.forgotPasswordForm.get('email').setValidators([Validators.email]);
        } else {
          this.forgotPasswordForm.get('email').setValidators([Validators.required]);
        }
      }
    )
  }

  async getCustomer(a) {

    if (a.length > 0) {
      this.Customer = [];
      const $this = this;
      await this.authService.getTenantId(a).subscribe(result => {
        $this.Tenant = [];
        result = result.body;
        if (result && result.length === 1) {
          localStorage.setItem('tenantid', result[0].tenantid);
          this.authService.checkUserClient(a).subscribe(
            res => {
              res.forEach(function (value) {
                $this.Customer.push(value);
              });
            });
        } else if (result && result.length > 1) {
          result.forEach(function (value) {
            $this.Tenant.push(value);
            localStorage.setItem('tenantid', value.tenantid);
          });
        }
      });

    }
  }


  tenantSelect(value) {
    this.Customer = [];
    localStorage.setItem('tenantid', value);
    this.authService.checkUserClient(this.forgotPasswordForm.get('email').value).subscribe(
      result => {
        result.forEach(function (data) {
          this.Customer.push(data);
        });
      });
  }


  forgotPasswordRecovery(data) {

    var email = data.email;
    const signUpDetails = new SignUpModel();
    signUpDetails.email = data.email;
    this.authService.forgot_password(email).subscribe(
      result => {
        console.log(result);
        if (result.httpCode === 200) {
          this._signup = result;
          this.isMailSent = true;
        } else {
          $.toaster({ priority: 'error', title: 'Error', message: result.failureReason });
        }

        // this.router.navigate(['/']);
      },
      err => {
        console.log(err);
      });
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  forgotPasswordPageLayout() {
    $(window.document).ready(function () {
      if ($(".login-page")[0]) {
      } else {
        $("body").addClass("login-page");
      }
      if ($(".skin-black")[0]) {
        $("body").removeClass("skin-black");
      }
      if ($(".sidebar-mini")[0]) {
        $("body").removeClass("sidebar-mini");
      }
      if ($(".hold-transition")[0]) {
      } else {
        $("body").addClass("hold-transition");
      }
      if ($(".login-box")[0]) {
      } else {
        $("#wrapper_id").addClass("login-box");
      }
      if ($(".wrapper")[0]) {
        $("#wrapper_id").removeClass("wrapper");
      }
      if ($(".register-box")[0]) {
        $("#wrapper_id").removeClass("register-box");
      }
      if ($(".register-page")[0]) {
        $("body").removeClass("register-page");
      }
      $("body").removeAttr("style");
      $("#wrapper_id").removeAttr("style");
    });
  }

  resolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse.length > 0) {
      this.isCaptcha = false;
    }
    console.log(`Resolved captcha with response ${captchaResponse}`);
  }


}
