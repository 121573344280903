import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TokenService } from '../services/token-service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private auth: TokenService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private _storageService: StorageService,
        public loaderService: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.skipLoader(req);
        const authHeader = this.auth.getAuthorizationHeader();
        const tenantid = localStorage.getItem('tenantid');
        const referBy = localStorage.getItem('referBy');
        let changepwdReq = req;
        if (authHeader) {
            if (authHeader.access_token && authHeader.client_id) {
                changepwdReq = req.clone({
                    headers: req.headers
                        .set('Authorization', authHeader.access_token)
                        .set('customer-id', authHeader.client_id)
                        .set('tenantid', tenantid)
                });
            } else if (authHeader.access_token) {
                changepwdReq = req.clone({
                    headers: req.headers
                        .set('Authorization', authHeader.access_token)
                        .set('tenantid', tenantid)
                });
            } else if ((tenantid || tenantid != null) && referBy === null) {
                changepwdReq = req.clone({
                    headers: req.headers
                        .set('tenantid', tenantid)
                });
            } else if ((tenantid || tenantid != null) && referBy !== null) {
                changepwdReq = req.clone({
                    headers: req.headers
                        .set('tenantid', tenantid)
                        .set('referralTenantid', referBy)
                });
            } else {
                // Do nothing
            }
        } else {
            changepwdReq = req.clone({
                headers: req.headers
                    .set('tenantid', tenantid)
            });
        }
        return next.handle(changepwdReq).do(
            event => {
                if (event instanceof HttpResponse) {
                    // this.spinner.hide();
                    this.loaderService.hide();
                }
            },
            err => {
                // this.spinner.hide();
                this.loaderService.hide();
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this._storageService.clearAll();
                        this.router.navigate(['/login']);
                    }
                }
            }
        );
    }
    skipLoader(req) {
        if (req.url.indexOf('users/client?') > 0
            || req.url.indexOf('for/hearing') > 0
            || req.url.indexOf('against/hearing') > 0) {
            console.log('Skipped');
        } else {
            // this.spinner.show();
            this.loaderService.show();
        }

    }
}
