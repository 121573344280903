import { Injectable } from '@angular/core';
import { endpoint_url } from '../shared-config';

/* const SockJs = require('sockjs-client');
const Stomp = require('stompjs'); */
import * as Stomp from 'stompjs';
import * as SockJs from 'sockjs-client';

@Injectable()
export class WebSocketService {

    // Open connection with the back-end socket
    public connect() {
        const socket = new SockJs(endpoint_url + '/socket');
        const stompClient = Stomp.over(socket);
        return stompClient;
    }
}
