import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResetPassword } from '../../shared/models/auth/resetpassword.model';
import { AuthService } from '../auth-shell.service';
import { matchValidator } from 'src/app/shared/Utility/util-custom.validation';

declare let $;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [AuthService]
})

export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  passwordValidationMessage = 'Password is required.';

  constructor(private router: Router, private fb: FormBuilder, private authService: AuthService) {
    this.resetPasswordForm = fb.group({
      confirmPassword: [null, Validators.compose([Validators.required, matchValidator('password')])],
      password: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.resetPasswordPageLayout();

    this.resetPasswordForm.get('password').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.passwordValidationMessage = 'Password must use a combination' +
            ' of these: Atleast 1 upper case letters (A – Z),' +
            ' one lower case letters (a – z)' +
            ' one number (0 – 9)' +
            ' one special symbol (e.g. ‘!@#\$%\^&\’)' +
            ' and minimum length should be 8 characters.';
        } else {
          this.passwordValidationMessage = 'Password is required.';
        }
      }
    );
  }



  resetPassword(data) {
    if (data.password !== '' || data.password != null && data.password === data.confirmPassword) {
      const shortLink = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
      const tenantId = shortLink.slice(shortLink.lastIndexOf('/') + 1);
      const token = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);
      const resetDetails = new ResetPassword();
      localStorage.clear();
      localStorage.setItem('tenantid', tenantId);
      resetDetails.token = token;
      resetDetails.email = data.email;
      resetDetails.password = data.password;

      this.authService.resetPassword(resetDetails).subscribe(
        res => {
          const result = res as any;
          if (result.status === 200) {
            $.toaster({ priority: 'success', title: 'Success', message: 'Password reset successfully' });
            this.router.navigate(['login']);
          }
        },
        err => {
          console.log(err);
        });
    }


  }

  resetPasswordPageLayout() {
    $(window.document).ready(function () {
      if ($('.login-page')[0]) {
      } else {
        $('body').addClass('login-page');
      }
      if ($('.skin-black')[0]) {
        $('body').removeClass('skin-black');
      }
      if ($('.sidebar-mini')[0]) {
        $('body').removeClass('sidebar-mini');
      }
      if ($('.hold-transition')[0]) {
      } else {
        $('body').addClass('hold-transition');
      }
      if ($('.login-box')[0]) {
      } else {
        $('#wrapper_id').addClass('login-box');
      }
      if ($('.wrapper')[0]) {
        $('#wrapper_id').removeClass('wrapper');
      }
      if ($('.register-box')[0]) {
        $('#wrapper_id').removeClass('register-box');
      }
      if ($('.register-page')[0]) {
        $('body').removeClass('register-page');
      }
      $('body').removeAttr('style');
      $('#wrapper_id').removeAttr('style');
    });
  }
}
