import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthShellComponent } from './auth-shell.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyEmailComponent } from './VerifyEmail/VerifyEmail.component';
import { VerifyUserComponent } from './verifyUser/verifyUser.component';
import { changepasswordComponent } from './changepassword/changepassword.component';
import { HomeComponent } from './home/home.component';
import { CallbackComponent } from './callback/callback.component';
const authShellRoutes: Routes = [
  {
    path: '', component: AuthShellComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'signup/:referralcode/:tenant', component: SignupComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      // { path: 'verifyemail', component: VerifyEmailComponent },
      { path: 'resetpwd', component: VerifyEmailComponent },
      { path: 'updatePassword/:tenant/:id', component: ResetPasswordComponent },
      { path: 'changepassword', component: changepasswordComponent },
      { path: 'verifyEmail/:tenant/:id', component: VerifyEmailComponent },
      { path: 'verifyUser/:tenant/:id', component: VerifyUserComponent },
      { path: 'home', component: HomeComponent },
      { path: 'paymentCallback', component: CallbackComponent}
    ]
  }
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(authShellRoutes)],
  exports: [RouterModule],
  declarations: [VerifyEmailComponent, VerifyUserComponent]
})
export class AuthShellRoutingModule { }