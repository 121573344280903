import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { BusyModule } from 'angular2-busy';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MasterTemplateService } from '../app/feature-shell/master/masterTemplates/masterTemplate.component.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthShellModule } from './auth-shell/auth-shell.module';
import { AuthInterceptor } from './shared/interceptor/auth-interceptor';
import { ApiGateway } from './shared/services/api-gateway';
import { LFAuthantication } from './shared/services/lfAuthantication-service';
import { StorageService } from './shared/services/storage.service';
import { TokenService } from './shared/services/token-service';
import { SharedModule } from './shared/shared.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material';
import { LoaderService } from './shared/services/loader.service';
import { WebSocketService } from './shared/services/webSocketService';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthShellModule,
    AppRoutingModule,
    Ng2CompleterModule,
    NgxPermissionsModule.forRoot(),
    // BusyModule,
    NgbModule.forRoot(),
    HttpClientModule,
    TableModule,
    PaginatorModule,
    NgxSpinnerModule,
    MatProgressBarModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ApiGateway,
    TokenService,
    MasterTemplateService,
    LFAuthantication,
    StorageService,
    LoaderService,
    WebSocketService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
