import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../auth-shell.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  paymentId;
  success;
  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public authService: AuthService, private ngZone: NgZone) {
    this.paymentId = this.data.paymentId;
    this.success = 'successfull';
    const paymentCaptureData = {
      paymentId: this.data.paymentId,
      amount: this.data.amount,
      currency: this.data.currency,
      orderId: this.data.order.id,
      user: this.data.user
    };
    if (this.paymentId) {
      this.capturePayment(paymentCaptureData);
    }
  }

  ngOnInit() {

  }

  capturePayment(paymentCaptureData) {
    this.authService.capturePayment(paymentCaptureData).subscribe(result => {
      console.log(result);
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    });
  }

  close() {
    this.dialogRef.close();
  }
}
