import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';
import { AuthShellRoutingModule } from './auth-shell-routing.module';
import { AuthShellComponent } from './auth-shell.component';
import { AuthService } from './auth-shell.service';
import { changepasswordComponent } from './changepassword/changepassword.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule, MatProgressSpinnerModule, MatTabsModule, MatCardModule, MatDialogModule} from '@angular/material';
import { DialogComponent } from './signup/dialog/dialog.component';
import { CallbackComponent } from './callback/callback.component';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthShellRoutingModule,
    SharedModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    RecaptchaModule.forRoot(),
   //TODO:
   NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '4px',
      primaryColour: '#blue',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    })
  ],
  providers: [AuthService],
  declarations: [
    AuthShellComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    changepasswordComponent,
    HomeComponent,
    DialogComponent,
    CallbackComponent
  ],
  entryComponents: [
    DialogComponent
  ]
})
export class AuthShellModule {}
