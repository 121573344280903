import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Http } from '@angular/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SignUpModel } from '../../shared/models/auth/signup.model';
import { KeyValue, UserRoles, UserStatus } from '../../shared/Utility/util-common';
import { matchValidator } from '../../shared/Utility/util-custom.validation';
import { AuthService } from '../auth-shell.service';
import uuid from 'uuidv4';
import { TokenService } from 'src/app/shared/services/token-service';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { MatStepper, MatDialog } from '@angular/material';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DialogComponent } from './dialog/dialog.component';


declare let $;
declare let subscriptionId;
declare var Razorpay: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [AuthService,
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }]
})

export class SignupComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;
  order;
  busy: Subscription;
  public loading = false;
  signupForm: FormGroup;
  public _signup: any;
  Roles: KeyValue[] = UserRoles;
  Status: KeyValue[] = UserStatus;
  emailValidationMessage = 'Email address is required.';
  passwordValidationMessage = 'Password is required.';
  zipValidationMessage = 'Postal/Zip Code is required.';
  mobileNoValidationMessage = 'Mobile number is required.';
  public isMailSent = false;
  Subscription: any[] = [];
  UserType: any[] = [];
  subscriptionId: number;
  isValid: boolean = false;
  selectedSubscription: any;
  isCaptcha: boolean = true;
  subsPlan = [];
  allPlans = [];
  lawyerPlans = [];
  lawyerFirmPlans = [];
  selectedPlan;
  completed1 = false;
  completed2 = false;
  lawyerFeature = [];
  lawyerFirmFeature = [];
  paymentId: string;
  //dataKey = 'rzp_test_iige84uUo5v9yk';
  dataKey = 'rzp_test_GQJX4OjDYl7VJ1';
  referrerTenantId: string;
  referralCode: string;
  constructor(private http: HttpClient, private router: Router, private fb: FormBuilder, private _httpClient: HttpClient,
    private authService: AuthService, private activatedRoute: ActivatedRoute,
    private tokenService: TokenService, public dialog: MatDialog) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.subscriptionId = params['subscription'];
      this.referrerTenantId = params['tenant'];
      this.referralCode = params['referralcode'];
    });
    this.createSignup(this.subscriptionId);
    this.getAllSubscription();
  }

  getAllSubscription() {
    this.authService.getAllPlans().subscribe(result => {
      this.allPlans = result;
      this.allPlans.forEach(p => {
        if (p.type === 'Lawyer') {
          this.lawyerPlans.push(p);
        } else {
          this.lawyerFirmPlans.push(p);
        }
      });
      this.lawyerPlans.forEach(p => {
        this.lawyerFeature = p.features.split(',');
      });
      this.lawyerFirmPlans.forEach(lfp => {
        this.lawyerFirmFeature = lfp.features.split(',');
      });
      console.log('Lawyer' + this.lawyerPlans);
      console.log(this.lawyerFirmPlans);
    });
  }

  ngOnInit() {
    localStorage.clear();
    if (this.referralCode && this.referrerTenantId) {
      // TODO: call backoffice api to change status pending to view
      const data = {
        referralCode: this.referralCode,
        referrerTenantId: this.referrerTenantId
      }
      this.authService.changeReferalStatus(data).subscribe(result => {
        console.log(result);
      })
    }
    if (this.referrerTenantId) {
      localStorage.setItem('referBy', this.referrerTenantId);
    }
    this.selectedPlan = '';
    this.getUserSubscription();
    this.getusersType();
    this.signupPageLayout();
    this.signupForm.get('email').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.emailValidationMessage = 'Email format is not correct.';
        } else {
          this.emailValidationMessage = 'Email address is required.';
        }
      }
    );

    this.signupForm.get('password').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.passwordValidationMessage = 'Password must use a combination' +
            ' of these: Atleast 1 upper case letters (A – Z),' +
            ' one lower case letters (a – z)' +
            ' one number (0 – 9)' +
            ' one special symbol (e.g. ‘!@#\$%\^&\’)' +
            ' and minimum length should be 8 characters.';
        } else {
          this.passwordValidationMessage = 'Password is required.';
        }
      }
    );

    this.signupForm.get('postalCode').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.zipValidationMessage = 'Postal/Zip Code length is less then 6';
        } else {
          this.zipValidationMessage = 'Postal/Zip Code is required';
        }
      }
    );

    this.signupForm.get('mobileNumber').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.mobileNoValidationMessage = 'Mobile number length is less then 10';
        } else {
          this.mobileNoValidationMessage = 'Mobile number is required.';
        }
      }
    );
  }

  signupPageLayout() {
    $(window.document).ready(function () {
      $('body').addClass('register-page');
      $('body').removeClass('skin-black');
      $('body').removeClass('sidebar-mini');
      $('body').addClass('hold-transition');
      $('body').removeAttr('style');
      $('#wrapper_id').removeClass('wrapper').addClass('register-box1');
      $('#wrapper_id').removeAttr('style');

    });
  }

  createSignup(subscriptionId) {

    this.signupForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      organisation: [null, Validators.required],
      addressLine1: [null, Validators.required],
      addressLine2: [null, Validators.required],
      postalCode: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.compose([Validators.required,
      Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,12}$/)])],
      confirmPassword: [null, Validators.compose([Validators.required, matchValidator('password')])],
      mobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      role: [1],
      subscription: [parseInt(subscriptionId), Validators.required],
      termsAndCondition: [false, Validators.pattern('true')],
    });
  }


  getUserSubscription() {
    this.Subscription = [];
    this.Subscription.push({ id: 1, name: 'TRIAL' }, { id: 2, name: 'PREMIUM' });
    this.selectedSubscription = this.Subscription[0].id;
  }

  getusersType() {
    this.UserType = [];
    this.UserType.push({ id: 1, name: 'Lawyer' }, { id: 2, name: 'Lawyer Firm' });
  }

  registerUser(data) {
    this.loading = true;
    const org = data.organisation.split(' ').join('_');
    let tenantData = {
      'email': data.email,
      'organisation': org
    };
    this.authService.setUpTenant(tenantData).subscribe(
      tenantsetUpDetails => {
        if (tenantsetUpDetails.body.isSucess === true) {
          console.log('success');
          localStorage.setItem('tenantid', tenantsetUpDetails.body.tenantid);
          const signUpDetails = new SignUpModel();
          {
            signUpDetails.address = {
              address1: data.addressLine1,
              address2: data.addressLine2,
              zipCode: data.postalCode
            };
            signUpDetails.email = data.email;
            signUpDetails.firstName = data.firstName;
            signUpDetails.isClient = 1;
            signUpDetails.lastName = data.lastName;
            signUpDetails.login = {
              userLoginId: data.email,
              password: data.password
            };
            signUpDetails.mobileNumber = data.mobileNumber;
            signUpDetails.organization = data.organisation;
            signUpDetails.password = data.password;
            signUpDetails.status = {
              statusId: 1,
            };
            signUpDetails.subscriptionId = this.selectedPlan;

            signUpDetails.customerType = {
              id: data.role,
            };
            signUpDetails.initialPayId = this.paymentId;

            this.authService.signup(signUpDetails).subscribe(

              result => {

                if (result.body.httpCode === 200) {
                  this.loading = false;
                  this._signup = result;
                  this.isMailSent = true;
                  $('#registermsg').hide();
                } else {
                  this.loading = false;
                  $.toaster({ priority: 'error', title: 'Error', message: result.body.failureReason });
                }

              },
              err => {
                console.log(err);
                this.loading = false;
              });

          }
        } else {
          this.loading = false;
          $.toaster({ priority: 'error', title: 'Error', message: tenantsetUpDetails.body.error_reason });
        }
      },
      err => {
        console.log(err);
        this.loading = false;
      });

  }


  redirectToLogin() {
    this.router.navigate(['login']);
  }

  resolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse.length > 0) {
      this.isCaptcha = false;
    }
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  choosedPlan(cp) {
    console.log(this.selectedPlan);
    if (cp && cp.plan === 'Trial') {
      this.selectedPlan = cp;
      if (this.selectedPlan.type === 'Lawyer') {
        this.signupForm.controls['role'].setValue(1);
      } else {
        this.signupForm.controls['role'].setValue(2);
      }
      this.completed2 = true;
      setTimeout(() => {
        this.registerUser(this.signupForm.value)
      }, 10);
    } else {
      console.log('redirect to razor payment gateway');
      this.selectedPlan = cp;
      const details = {
        amount: this.selectedPlan.price,
        currency: 'INR',
        receipt: '',
        payment_capture: false,
      };
      this.authService.createPaymentDetails(details).subscribe(order => {
        this.order = order.body;
        this.proceedToCheckout(this.order);
      });
    }
  }
  //'key_id': 'rzp_test_Kx3QpgXx7nUop6',
  //'image': 'https://legalmozo.com/assets/dist/img/new_logo.png',
  proceedToCheckout(order) {
    const data = {
      'key_id': 'rzp_test_GQJX4OjDYl7VJ1',
      'order_id': order.id,
      'name': 'LegalMozo',
      'description': 'Plan Purchase',
      'image': 'https://cdn.razorpay.com/logos/DxZEKxkjwLPZVu_medium.png',
      'prefill': {
        'name': this.signupForm.get('firstName').value + ' ' + this.signupForm.get('lastName').value,
        'contact': this.signupForm.get('mobileNumber').value,
        'email': this.signupForm.get('email').value
      },
      'notes': 'Test payment',
      'handler': function (response) {
        console.log(response);
        this.paymentId = response.razorpay_payment_id;
        if (this.paymentId) {
          this.openDialog(response.razorpay_payment_id);
          if (this.selectedPlan.type === 'Lawyer') {
            this.signupForm.controls['role'].setValue(1);
          } else {
            this.signupForm.controls['role'].setValue(2);
          }
        }
      }.bind(this),
    };

    $.getScript('https://checkout.razorpay.com/v1/checkout.js', function () {
      const rzp1 = new Razorpay(data);
      rzp1.open();
    });

  }

  openDialog(payment_id): void {
    if (payment_id) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '350px',
        data: {
          paymentId: payment_id,
          amount: this.selectedPlan.price,
          currency: 'INR',
          order: this.order,
          user: this.signupForm.get('email').value,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.completed2 = true;
        setTimeout(() => {
          this.registerUser(this.signupForm.value);
        }, 10);
      });
    }
  }


  checkValidity1() {
    if (this.signupForm.get('firstName').valid
      && this.signupForm.get('lastName').valid
      && this.signupForm.get('organisation').valid
      && this.signupForm.get('addressLine1').valid
      && this.signupForm.get('addressLine2').valid
      && this.signupForm.get('postalCode').valid
      && this.signupForm.get('email').valid
      && this.signupForm.get('mobileNumber').valid
      && this.signupForm.get('password').valid
      && this.signupForm.get('confirmPassword').valid
      && this.signupForm.get('termsAndCondition').valid) {
      this.completed1 = true;
      setTimeout(() => {
        this.stepper.next();
      }, 10);
      this.stepper.next();
    } else {
      if (this.signupForm.get('firstName').invalid) {
        this.signupForm.get('firstName').markAsTouched();
      }
      if (this.signupForm.get('lastName').invalid) {
        this.signupForm.get('lastName').markAsTouched();
      }
      if (this.signupForm.get('organisation').invalid) {
        this.signupForm.get('organisation').markAsTouched();
      }
      if (this.signupForm.get('addressLine1').invalid) {
        this.signupForm.get('addressLine1').markAsTouched();
      }
      if (this.signupForm.get('addressLine2').invalid) {
        this.signupForm.get('addressLine2').markAsTouched();
      }
      if (this.signupForm.get('postalCode').invalid) {
        this.signupForm.get('postalCode').markAsTouched();
      }
      if (this.signupForm.get('email').invalid) {
        this.signupForm.get('email').markAsTouched();
      }
      if (this.signupForm.get('mobileNumber').invalid) {
        this.signupForm.get('mobileNumber').markAsTouched();
      }
      if (this.signupForm.get('password').invalid) {
        this.signupForm.get('password').markAsTouched();
      }
      if (this.signupForm.get('confirmPassword').invalid) {
        this.signupForm.get('confirmPassword').markAsTouched();
      }
      if (this.signupForm.get('termsAndCondition').invalid) {
        this.signupForm.get('termsAndCondition').markAsTouched();
        alert("Pls select Terms And Conditions.");
      }
      return false;
    }
  }

  checkExistingEmail(email) {
    this.authService.findExistingEmail(email).subscribe(res => {
      if (res.error_code === 500) {
        this.signupForm.controls['email'].setErrors({ 'incorrect': true });
        this.emailValidationMessage = 'Email is already registered';
      } else if (res.error_code !== 500) {
        this.signupForm.get('email').valueChanges.subscribe(
          (e) => {
            if (e !== '') {
              this.emailValidationMessage = 'Email format is not correct.';
            } else {
              this.emailValidationMessage = 'Email address is required.';
            }
          }
        );
      } else {
        this.signupForm.controls['email'].setErrors(null);
      }
    });
  }

}

