import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthShellModule } from './auth-shell/auth-shell.module';
const routes: Routes = [
    { path: 'auth', component: AuthShellModule },
    { path: 'app', loadChildren: './feature-shell/feature-shell.module#FeatureShellModule' },
    { path: '', redirectTo: 'auth', pathMatch: 'full' }
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, {enableTracing: false, errorHandler: (error) => {
    console.log(error)
}});
