import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenModel } from '../../shared/models/auth/token.model';
import { AuthService } from '../auth-shell.service';

declare let $;
@Component({
    selector: 'app-verify-user',
    templateUrl: './verifyUser.component.html',

    providers: [AuthService]
})

export class VerifyUserComponent implements OnInit {
    message;
    isSuccess = false;
    public changePasswordForm: FormGroup;
    constructor(private router: Router, private fb: FormBuilder, private authService: AuthService) {
        this.changePasswordForm = fb.group({
            newPassword: [null, Validators.required],
            confirmPassword: [null, Validators.required]
        });
    }
    ngOnInit() {
        this.changePasswordPageLayout();
        this.verifyEmail();
    }

    verifyEmail() {
        $(function () {
            $("#verified").hide();
            $("#unverified").hide();
        });
        var isReferral;
        const str1 = window.location.href.slice(window.location.href.indexOf('/'));
        const str2 = 'verifyReferralEmail';
        if (str1.indexOf(str2) !== -1) {
            isReferral = 'Y';
        } else {
            isReferral = 'N';
        }
        const shortLink = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
        const tenantId = shortLink.slice(shortLink.lastIndexOf('/') + 1);
        const token = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);
        localStorage.clear();
        localStorage.setItem('tenantid', tenantId);
        this.authService.verifyemail(token, isReferral).subscribe(
            result => {
                if (result.body.successMessage === null) {
                    this.message = result.body.failureReason;
                    this.isSuccess = false;
                    $('#verified').hide();
                    $('#unverified').show();
                } else {
                    this.message = result.body.successMessage;
                    this.isSuccess = true;
                    $('#verified').show();
                    $('#unverified').hide();
                }
            },
            err => {
                console.log(err);
            });
    }

    changePassword(data) {
        var isReferral;
        const str1 = window.location.href.slice(window.location.href.indexOf('/'));
        const str2 = 'verifyReferralEmail';
        if (str1.indexOf(str2) !== -1) {
            isReferral = 'Y';
        } else {
            isReferral = 'N';
        }
        const shortLink = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
        const tenantId = shortLink.slice(shortLink.lastIndexOf('/') + 1);
        const token = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);
        const tokenDetails = new TokenModel();
        tokenDetails.token = token;
        tokenDetails.isReferral = isReferral;
        tokenDetails.oldPassword = data.newPassword;
        tokenDetails.password = data.confirmPassword;
        const password = data.confirmPassword;
        localStorage.clear();
        localStorage.setItem('tenantid', tenantId);

        this.authService.verifyUser(token, isReferral, password).subscribe(

            result => {
                if (result.body.httpCode === 200) {
                    $.toaster({ priority: 'success', title: 'Success', message: 'User Verify Successfully' });
                } else {
                    $.toaster({ priority: 'error', title: 'Error', message: result.body.failureReason });
                }
                setTimeout(() => {
                    window.location.href = 'login';
                }, 3000);

            },
            err => {
                console.log(err);
            });
    }

    getColor() {
        if (this.isSuccess) {
            return 'green';
        } else {
            return 'red';
        }
    }

    changePasswordPageLayout() {
        $(window.document).ready(function () {
            if ($(".login-page")[0]) {
            } else {
                $("body").addClass("login-page");
            }
            if ($(".skin-black")[0]) {
                $("body").removeClass("skin-black");
            }
            if ($(".sidebar-mini")[0]) {
                $("body").removeClass("sidebar-mini");
            }
            if ($(".hold-transition")[0]) {
            } else {
                $("body").addClass("hold-transition");
            }
            if ($(".login-box")[0]) {
            } else {
                $("#wrapper_id").addClass("login-box");
            }
            if ($(".wrapper")[0]) {
                $("#wrapper_id").removeClass("wrapper");
            }
            if ($(".register-box")[0]) {
                $("#wrapper_id").removeClass("register-box");
            }
            if ($(".register-page")[0]) {
                $("body").removeClass("register-page");
            }
            $("body").removeAttr("style");
            $("#wrapper_id").removeAttr("style");
        });
    }
}
