import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
// import { UserRoles, UserStatus, KeyValue } from '../../shared/Utility/util-common';
import { matchValidator } from '../../shared/Utility/util-custom.validation';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { LoginModel } from '../../shared/models/auth/login.model';
import { AuthService } from '../auth-shell.service';

declare let $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {
  //   columns = [
  //     {
  //       'name': 'stageName'
  //     }
  //   ];
  //   stage = 'stageName';
  // dropdownData = {
  //   'stageName' : {
  //     selected: 'yes'
  //   }
  // };
  public loginForm: FormGroup;
  emailValidationMessage: string = 'Email address is required.';
  public _login: any;
  isLoggedInError = false;
  public submitted: boolean;
  public events: any[] = [];
  Customer: any = [];
  Tenant: any = [];
  errLoginMsg = '';
  isCaptcha: boolean = true;
  isSuspendedError = false;
  isRememberCheck;
  public loading = false;
  returnUrl = '';
  urlParams: any = null;
  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private authService: AuthService) {
    const isSignedOut = localStorage.getItem('is_signed_out');
    if (isSignedOut) {
      window.location.reload();
      localStorage.removeItem('is_signed_out');
    }
    this.loginForm = fb.group({
      email: [null, Validators.required],
      password: [null, [Validators.required, Validators.maxLength(12)]],
      clientId: [],
      isRemember: [],
      tenantId: [],
    });
  }
  getUrlParams(actualURL) {
    let params = {}
    if (actualURL.indexOf('?') != -1) {
      let hashes = actualURL.slice(actualURL.indexOf('?') + 1).split('&');
      hashes.map(hash => {
        let [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
      })
    }
    return params
  }
  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (this.returnUrl && this.returnUrl.indexOf('?') != -1) {
      this.urlParams = this.getUrlParams(this.returnUrl);
      this.returnUrl = this.returnUrl.split('?')[0];
    }
    this.loginForm.disable();
    document.getElementById('overlay').style.display = 'block';
    $('#overlay').css('display', 'block');
    // localStorage.clear();
    this.loginPageLayout();
    // check whether user is loggedin or not if user loggedin then redirect to dashboard
    const accessToken = localStorage.getItem('access_token');
    const isRemember = localStorage.getItem('isRemember');
    const userId = localStorage.getItem('user_id');
    console.log('accessToken: ' + accessToken);
    console.log('isRemember: ' + isRemember);
    if (accessToken && isRemember === 'true' && userId) {
      const client = '?userId=' + localStorage.getItem('client_id');
      // document.getElementById('overlay').style.display = 'none';
      this.authService.getUser(client).subscribe(
        userDetails => {
          if (userDetails) {
            this.setUserRole(userDetails);
            const permission = localStorage.getItem('permission_level');
            if (permission.indexOf('CLIENT') >= 0) {
              if (permission === 'CLIENT_LAWYER_FIRM_Institutional') {
                this.router.navigateByUrl('app/institution');
                this.loginForm.enable();
                document.getElementById('overlay').style.display = 'none';
                $('#overlay').css('display', 'none');
              } else {
                this.router.navigateByUrl('app/case');
                this.loginForm.enable();
                document.getElementById('overlay').style.display = 'none';
                $('#overlay').css('display', 'none');
              }
            } else {
              this.router.navigateByUrl('app/dashboard');
              this.loginForm.enable();
              document.getElementById('overlay').style.display = 'none';
              $('#overlay').css('display', 'none');
            }
          }
        },
        error => {
          console.log(error);
          document.getElementById('overlay').style.display = 'none';
          $('#overlay').css('display', 'none');
        }
      );
    } else {
      localStorage.clear();
      this.loginForm.enable();
      $('#overlay').hide();
      document.getElementById('overlay').style.display = 'none';
    }


    this.loginForm.get('email').valueChanges.subscribe(
      (e) => {
        if (e !== '') {
          this.loginForm.get('email').setValidators([Validators.email]);
          this.emailValidationMessage = 'Email format is not correct.';
        } else {
          this.loginForm.get('email').setValidators([Validators.required]);
          this.emailValidationMessage = 'Email address is required.';
        }
      }
    );
  }

  loginPageLayout() {
    $(window.document).ready(function () {
      if ($('.login-page')[0]) {
      } else {
        $('body').addClass('login-page');
      }
      if ($('.skin-black')[0]) {
        $('body').removeClass('skin-black');
      }
      if ($('.sidebar-mini')[0]) {
        $('body').removeClass('sidebar-mini');
      }
      if ($('.hold-transition')[0]) {
      } else {
        $('body').addClass('hold-transition');
      }
      /* if ($('.login-box')[0]) {
      } else {
        $('#wrapper_id').addClass('login-box');
      } */
      if ($('.wrapper')[0]) {
        $('#wrapper_id').removeClass('wrapper');
      }
      if ($('.register-box')[0]) {
        $('#wrapper_id').removeClass('register-box');
      }
      if ($('.register-page')[0]) {
        $('body').removeClass('register-page');
      }
      $('body').removeAttr('style');
      $('#wrapper_id').removeAttr('style');
    });
  }

  register(): void {
    this.router.navigate(['signup']);
  }

  forgotPassword() { }

  async getCustomer(a) {

    if (a.length > 0) {
      this.Customer = [];
      const $this = this;
      await this.authService.getTenantId(a).subscribe(result => {
        $this.Tenant = [];
        if (result && result.body.length === 1) {
          localStorage.setItem('tenantid', result.body[0].tenantid);
          this.authService.checkUserClient(a).subscribe(
            res => {
              res.forEach(function (value) {
                $this.Customer.push(value);
              });
            });
        } else if (result && result.body.length > 1) {
          result.body.forEach(function (value) {
            $this.Tenant.push(value);
          });
        }
      });

    }
  }

  tenantSelect(value) {
    this.Customer = [];
    localStorage.setItem('tenantid', value);
    this.authService.checkUserClient(this.loginForm.get('email').value).subscribe(
      result => {
        result.forEach(function (data) {
          this.Customer.push(data);
        });
      });
  }

  setUserRole(data) {
    const userRole = data.roles[0].roleName;
    localStorage.setItem('userRole', userRole);
    let customerType = '';
    if (data.customerType) {
      customerType = '_' + data.customerType['name'];
    }
    let userType = '';
    if (data.userType) {
      userType = '_' + data.userType['name'];
    }
    const permission = userRole + customerType + userType;
    localStorage.setItem('permission_level', permission);
  }

  isRememberChecked(event) {
    if (event.target.checked) {
      this.isRememberCheck = 'true';
    } else {
      this.isRememberCheck = 'false';
    }
  }

  async login(data) {
    this.loading = true;
    const loginDetails = new LoginModel();
    loginDetails.username = data.email;
    loginDetails.password = data.password;
    const tenant = localStorage.getItem('tenantid');
    if (tenant === null) {
      const selectElement =
        document.querySelector('#tenantSelect') as HTMLSelectElement;
      if (selectElement) {
        localStorage.setItem('tenantid', selectElement.value);
      }
    }
    await this.authService.login(loginDetails).subscribe(
      result => {
        this._login = result;
        if (this._login.status === 203) {
          this.isSuspendedError = true;
          this.errLoginMsg = 'Your account has been suspended please contact your administrator.';
          this.loading = false;
          // $.toaster({ priority: 'error', title: 'Error', message: this.errLoginMsg });
        } else {
          if (this._login.body && this._login.body.token) {
            const accessToken = this._login.body.token;
            const clientId = this._login.body.clientId;
            if (this.isRememberCheck === 'true') {
              localStorage.setItem('isRemember', this.isRememberCheck);
            }
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('client_id', clientId);
            localStorage.setItem('user_id', data.email);
            const client = '?userId=' + localStorage.getItem('client_id');
            // localStorage.setItem('permission_level', 'ADMIN_LAWYER');
            // const permission = localStorage.getItem('permission_level');
            // console.log(permission);
            this.authService.getUser(client).subscribe(
              userDetails => {
                if (userDetails) {
                  localStorage.setItem('userDetails', JSON.stringify(userDetails));
                  this.setUserRole(userDetails);
                  const permission = localStorage.getItem('permission_level');
                  if (this.returnUrl) {
                    this.router.navigate([(this.returnUrl)], { queryParams: this.urlParams });
                  }
                  else if (permission.indexOf('CLIENT') >= 0) {
                    if (permission === 'CLIENT_LAWYER_FIRM_Institutional') {
                      this.router.navigateByUrl('app/institution');
                    } else {
                      this.router.navigateByUrl('app/case');
                    }
                  } else {
                    this.router.navigateByUrl('app/dashboard');
                  }
                  setTimeout(() => {
                    this.loading = false;
                  }, 5000);
                }
              },
              error => {
                console.log(error);
                this.loading = false;
              }
            );
            this.isLoggedInError = false;
          } else {
            $.toaster({ priority: 'error', title: 'Error', message: 'Please verify your email ID.' });
            this.isLoggedInError = true;
            this.errLoginMsg = 'Please verify your email ID.';
            this.loading = false;
            return;
          }
        }
      },
      err => {
        if (err.status === 403) {
          this.errLoginMsg = 'Invalid Username or Password';
          this.isLoggedInError = true;
          this.loading = false;
          console.log(err);
        } else {
          $.toaster({ priority: 'error', title: 'Error', message: 'Unable to connect to server, Please contact to administrator.' });
          this.loading = false;
          localStorage.clear();
        }
      });
  }

  resolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse.length > 0) {
      this.isCaptcha = false;
    }
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  giveTenantName(id) {
    return id.split('_')[0];
  }

}